:where(.css-dev-only-do-not-override-acm2ia).ant-radio-group {
  display: none !important;
}

:where(.css-dev-only-do-not-override-acm2ia).ant-picker-calendar
  .ant-picker-calendar-header {
  justify-content: flex-start;
  margin-left: 20px;
}

:where(.css-dev-only-do-not-override-acm2ia).ant-picker-calendar {
  background: none !important;
}

:where(.css-acm2ia).ant-picker-calendar .ant-picker-calendar-header {
  justify-content: flex-start;
  margin-left: 20px;
}

:where(.css-acm2ia).ant-picker-calendar {
  background: none !important;
}

:where(.css-acm2ia).ant-picker-calendar
  .ant-picker-calendar-header
  .ant-picker-calendar-mode-switch {
  display: none;
}
