.spin-detail {
  position: relative;
  padding: 1.25rem;
  &::after {
    content: "";
    position: absolute;
    top: 2rem;
    left: 0.5rem;
    width: 2px;
    height: calc(100% - 4rem);
    background-color: #4ec2f0;
  }
  .spin-time-line {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: -1.04rem;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #7987a1;
      translate: 0 -50%;
      z-index: 5;
    }
    &.transparent::after {
      background-color: #fff;
      border: 1px solid #7987a1;
      left: -1.1rem;
      top: calc(1rem + 20px);
    }
    .icon-holder {
      width: 40px;
      height: 40px;
    }
    .spin-detail-collapse {
      padding-left: 3.65rem;
    }

    @media (max-width: 500px) {
      .spin-detail-collapse {
        padding-left: 0;
      }
    }
  }
  .history-block {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 2.4rem;
      left: -2rem;
      width: 2px;
      height: calc(100% - 4rem);
      background-color: #4ec2f0;
    }
    .spin-time-line {
      &::after {
        top: 50%;
        left: -2.35rem;
      }
      &.transparent::after {
        background-color: #fff;
        border: 1px solid #7987a1;
        top: 50%;
        left: -2.35rem;
      }
    }
  }
}

@media (max-width: 500px) {
  .my-2 {
    margin-right: 10px;
  }
}
