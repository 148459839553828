.editor-button {
    color: #323251 !important;
    border-color: #323251 !important;
}

.dark-theme .editor-button {
    color: rgb(151, 163, 185) !important;
    border-color: #323251 !important;
}

.btn-outline-light {
    color: #323251;
    border-color: #323251 !important;
}

.MuiToggleButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.44) !important;
}

@media (max-width: 480px) {
    .editor {
        padding-left: 0;

        &__button {
            width: 170px;
        }
    }
}
