.table-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
}

.columns-container {
  display: flex;
  flex-direction: row;
}

.column-container {
  width: 100%;
  max-height: 480px;
}

.first-row-container {
  display: flex;
  flex-direction: row;
  max-height: 150px;
  position: relative;
}

.symbol-container {
  margin: 0;
  padding: 0;
  position: relative;
}

.symbol-size-1 {
  width: 100px;
  height: 157.5px;
  background-repeat: no-repeat;
  background-size: contain;
}

.symbol-size-first-row {
  width: 100px;
  height: 106px;
  background-repeat: no-repeat;
  background-size: contain;
}

.symbol-size-2 {
  width: 100px;
  height: 105px;
  background-repeat: no-repeat;
  background-size: contain;
}

.symbol-size-3 {
  width: 100px;
  height: 79px;
  background-repeat: no-repeat;
  background-size: contain;
}

.symbol-size-4 {
  width: 100px;
  height: 63px;
  background-repeat: no-repeat;
  background-size: contain;
}

.symbol-size-5 {
  width: 100px;
  height: 52.7px;
  background-repeat: no-repeat;
  background-size: contain;
}

.symbol-size-6 {
  width: 100px;
  height: 45.2px;
  background-repeat: no-repeat;
  background-size: contain;
}

.column-0 {
  display: none;
}

.table-div-notAvalanche {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.columns-container-notAvalanche {
  display: grid;
  gap: 10px;
}

.column-container-notAvalanche {
  display: flex;
  flex-direction: column;
}

.symbol-container-notAvalanche {
  width: 100px;
  height: 100px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.winNumber {
  font-family: "TitaniaRegular";
  font-size: 52px;
  position: relative;
  text-align: center;
  color: #f7df3f;
  text-shadow: -1px -1px 0 red, 1px -1px 0 red, -1px 1px 0 red, 1px 1px 0 red;
}

.winNumberAvalanche {
  position: absolute;
  left: 2px;
  top: 0;
  display: inline-block;
  font-family: "TitaniaRegular";
  font-size: 18px;
  color: #f73fcf;
  text-shadow: 0px 0px 2px #ffffff, 0px 0px 4px #ffffff, 0px 2px 2px #ffffff,
    0px 2px 4px #ffffff, 0px -2px 2px #ffffff, 2px 0px 2px #ffffff,
    -2px 0px 2px #ffffff;
}

.winNumberGalaAvalanche {
  display: flex;
  justify-content: center;
  font-family: "TitaniaRegular";
  font-size: 20px;
  color: gold;
  text-shadow: 0px 0px 0 #fa0fc7, 0px 0px 0 #fa0fc7, 0px 1px 0 #fa0fc7,
    0px 1px 0 #fa0fc7;
}

@media (max-width: 890px) {
  .table-div {
    margin-top: 20px;
  }

  .winNumber {
    font-size: 27px !important;
  }

  .symbol-size-first-row {
    width: 70px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-1 {
    width: 75px;
    height: 130.1px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-2 {
    width: 76px;
    height: 86.2px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-3 {
    width: 73px;
    height: 64.9px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-4 {
    width: 80px;
    height: 53px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-5 {
    width: 80px;
    height: 45.7px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-6 {
    width: 73px;
    height: 36.9px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media (max-width: 775px) {
  .table-div {
    margin-top: 20px;
  }

  .symbol-size-first-row {
    width: 70px;
    height: 82px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-1 {
    width: 70px;
    height: 120px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-2 {
    width: 70px;
    height: 79.8px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-3 {
    width: 70px;
    height: 60px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-4 {
    width: 69px;
    height: 47.9px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-5 {
    width: 70px;
    height: 40.1px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-6 {
    width: 68px;
    height: 34.3px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media (max-width: 738px) {
  .table-div {
    margin-top: 20px;
  }

  .winNumber {
    font-size: 23px !important;
  }

  .symbol-size-first-row {
    width: 64px;
    height: 75px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-1 {
    width: 61px;
    height: 105px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-2 {
    width: 60px;
    height: 70px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-3 {
    width: 60px;
    height: 52.6px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-4 {
    width: 60px;
    height: 41.5px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-5 {
    width: 60px;
    height: 35.1px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-6 {
    width: 59px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media (max-width: 685px) {
  .table-div {
    margin-top: 20px;
  }

  .winNumber {
    font-size: 18px !important;
  }

  .symbol-size-first-row {
    width: 60px;
    height: 70px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-1 {
    width: 50px;
    height: 87px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-2 {
    width: 50px;
    height: 58px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-3 {
    width: 50px;
    height: 43.5px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-4 {
    width: 50px;
    height: 35px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-5 {
    width: 50px;
    height: 29px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-6 {
    width: 48px;
    height: 25px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .winNumberAvalanche {
    font-size: 12px;
  }
}
@media (max-width: 555px) {
  .winNumberAvalanche {
    font-size: 10px;
  }
}

@media (max-width: 415px) {
  .table-div {
    margin-top: 20px;
  }

  .winNumber {
    font-size: 13px !important;
  }

  .symbol-size-first-row {
    width: 50px;
    height: 58px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-1 {
    width: 35px;
    height: 60px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-2 {
    width: 35px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-3 {
    width: 35px;
    height: 30.3px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-4 {
    width: 35px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-5 {
    width: 35px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .symbol-size-6 {
    width: 35px;
    height: 17px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
