.table-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
}

.columns-container {
  display: flex;
  flex-direction: row;
}

.column-container {
  max-height: 950px;
}

.first-row-container {
  display: flex;
  flex-direction: row;
  max-height: 150px;
  position: relative;
}

.symbol-container {
  margin: 0;
  padding: 0;
  position: relative;
}

.symbol-size_1 {
  width: 100px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
}

.symbol-size-first-row {
  width: 100px;
  height: 106px;
  background-repeat: no-repeat;
  background-size: contain;
}

.symbol-size_2 {
  width: 100px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
}

.symbol-size_3 {
  width: 100px;
  height: 240px;
  background-repeat: no-repeat;
  background-size: contain;
}

.symbol-size_4 {
  width: 100px;
  height: 318px;
  background-repeat: no-repeat;
  background-size: contain;
}

.symbol-size_5 {
  width: 100px;
  height: 52.7px;
  background-repeat: no-repeat;
  background-size: contain;
}

.symbol-size_6 {
  width: 100px;
  height: 45.2px;
  background-repeat: no-repeat;
  background-size: contain;
}

.column-0 {
  display: none;
}

.table-div-notAvalanche {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.columns-container-notAvalanche {
  display: grid;
  gap: 10px;
}

.column-container-notAvalanche {
  display: flex;
  flex-direction: column;
}

.symbol-container-notAvalanche {
  width: 100px;
  height: 100px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.symbol-size-1 {
  height: 157.5px;
}

.symbol-size-2 {
  height: 105px;
}

.symbol-size-3 {
  height: 79px;
}

.symbol-size-4 {
  height: 63px;
}

.symbol-size-5 {
  height: 52.7px;
}

.symbol-size-6 {
  height: 45.2px;
}
/* 
@media (max-width: 500px) {
  .table-div {
    display: flex;
    flex-direction: column;
    width: 55%;
    height: auto;
    justify-content: center;
    align-items: center;
    scale: 0.55;
  }
} */
